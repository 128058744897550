import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bench Press 10×10 @ 55% 1RM with 1:00 rest between sets`}</p>
    <p><em parentName="p">{`*`}{`Score = `}{`#`}{` of completed rounds.  Each set must be 10 unbroken reps.
 If you fail to get all the reps record your score and continue for the
remainder of the 10 sets with as many reps as possible.`}</em></p>
    <p><em parentName="p">{`*`}{`*`}{`Have a spotter for all 10 rounds then switch and spot your
partner.`}</em></p>
    <p>{`then,`}</p>
    <p>{`5 Rounds for time of:`}</p>
    <p>{`5-Renegade Rows per Arm, alternating arms (35/20)(RX+ =45/30)`}</p>
    <p>{`5-Unilateral DB Shoulder Press per Arm, alternating arms (35/20)(RX+ =
45/30)`}</p>
    <p>{`20-Situps`}</p>
    <p>{`60-Jump Rope Singles`}</p>
    <p><strong parentName="p">{`*`}{`Sunday starts test week!`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      